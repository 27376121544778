<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16">
        <el-radio-group v-model="dept_id" class="vg_mr_8" size="mini" @change="getDataList">
          <el-radio-button :label="6">采购一部</el-radio-button>
          <el-radio-button :label="7">采购二部</el-radio-button>
          <el-radio-button :label="20">辅料部</el-radio-button>
        </el-radio-group>
        <el-date-picker v-model="dateRange" class="vg_ml_8 vg_mr_8 topDateRangeSearch" placeholder="统计年份" size="small" type="year" value-format="yyyy" />
        <el-button class="vd_export" size="small" type="primary" @click="getDataList()">查询</el-button>
        <el-button class="vd_export" icon="el-icon-refresh-right" size="small" type="info" @click="buttonRefresh()"> 刷新 </el-button>
        <el-button class="vd_export" size="small" type="success" @click="exportExcel()"> 导出Excel </el-button>
      </div>
      <DynamicUTable
        ref="partMultiTable"
        v-loading="loadFlag"
        :columns="tableProperties"
        :need-check-box="false"
        :need-fixed-height="true"
        :need-pagination="false"
        :need-search="true"
        :needAllColumnSummary="true"
        :showSummary="true"
        :skip-summary-index="[1, 2, 3, 4]"
        :tableData="tableData"
        :totalPage="totalPage"
        @getTableData="getDataList"
      >
      </DynamicUTable>
    </el-card>
  </div>
</template>

<script>
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { cloneDeep } from 'lodash';
import { tableProperties } from '@/views/StatisticsManagement/SuppMonthlyStatistics/sms';
import { downloadFile } from '@api/public';
import { stisAPI } from '@api/modules/stis';

export default {
  name: 'pomodrSumStatistics',
  components: {
    DynamicUTable
  },
  data() {
    return {
      dateRange: [],
      dateRange2: [],
      tableProperties: tableProperties,
      tableData: [],
      totalPage: 0,
      btn: {},
      loadFlag: true,
      dept_id: this.$cookies.get('userInfo').dept_id
    };
  },
  created() {
    if (![6, 7, 20].includes(this.dept_id)) this.dept_id = 6;
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      this.getDataList();
      this.changeTableProperties();
    },
    async changeTableProperties() {},
    getSearchForm() {
      let searchForm = cloneDeep(this.$refs.partMultiTable.searchForm);
      searchForm.dept_id = this.dept_id;
      searchForm.year = this.dateRange;
      searchForm.suppAbbrList = searchForm.suppAbbrList?.toString();
      searchForm.suppNoList = searchForm.suppNoList?.toString();
      return searchForm;
    },
    //获取数据
    async getDataList() {
      this.loadFlag = true;
      let searchForm = this.getSearchForm();
      await stisAPI.getSuppMonthlyStatistics(searchForm).then(({ data }) => {
        this.tableData = data.list;
        this.totalPage = data.total;
        this.tableProperties.forEach(item => {
          if (item.otherProp === 'suppAbbrList') item.options = data.suppAbbrList;
          if (item.otherProp === 'suppNoList') item.options = data.suppNoList;
        });
        this.loadFlag = false;
      });
    },
    //跨分页导出excel
    exportExcel() {
      stisAPI.excelSuppMonthlyStatistics(this.getSearchForm()).then(({ data }) => {
        downloadFile({ fileUrl: data.url, fileName: data.fileName, type: '' });
      });
    },
    //刷新
    buttonRefresh() {
      this.$refs.partMultiTable.resetFields();
      this.dateRange = [];
      this.getDataList();
    }
  }
};
</script>

<style lang="scss" scoped>
.vd_elimg {
  width: 60px;
  height: 60px;
}
</style>
