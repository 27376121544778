export const tableProperties = [
  {
    label: '供应商代码',
    otherProp: 'suppNoList',
    prop: 'supp_no',
    itemType: 'select',
    options: [],
    input: true,
    sortable: false,
    multiple: true,
    filterable: true,
    collapseTags: true
  },
  {
    label: '供应商简称',
    otherProp: 'suppAbbrList',
    prop: 'supp_abbr',
    itemType: 'select',
    options: [],
    input: true,
    sortable: false,
    multiple: true,
    filterable: true,
    collapseTags: true
  },
  {
    label: '年度总数量',
    prop: 'yearTotalNum',
    itemType: 'input',
    input: false,
    sortable: true
  },
  {
    label: '年度总金额',
    prop: 'yearTotalPrice',
    itemType: 'input',
    input: false,
    sortable: true
  },
  {
    label: '1月',
    prop: '1月',
    secondaryHeader: [
      {
        label: '1月总数量',
        prop: 'janNum',
        itemType: 'input',
        input: false,
        sortable: true
      },
      {
        label: '1月总金额',
        prop: 'janPrice',
        itemType: 'input',
        input: false,
        sortable: true
      }
    ]
  },
  {
    label: '2月',
    prop: '2月',
    secondaryHeader: [
      {
        label: '2月总数量',
        prop: 'febNum',
        itemType: 'input',
        input: false,
        sortable: true
      },
      {
        label: '2月总金额',
        prop: 'febPrice',
        itemType: 'input',
        input: false,
        sortable: true
      }
    ]
  },
  {
    label: '3月',
    prop: '3月',
    secondaryHeader: [
      {
        label: '3月总数量 ',
        prop: 'marNum',
        itemType: 'input',
        input: false,
        sortable: true
      },
      {
        label: '3月总金额 ',
        prop: 'marPrice',
        itemType: 'input',
        input: false,
        sortable: true
      }
    ]
  },
  {
    label: '4月',
    prop: '4月',
    secondaryHeader: [
      {
        label: '4月总数量 ',
        prop: 'aprNum',
        itemType: 'input',
        input: false,
        sortable: true
      },
      {
        label: '4月总金额 ',
        prop: 'aprPrice',
        itemType: 'input',
        input: false,
        sortable: true
      }
    ]
  },
  {
    label: '5月',
    prop: '5月',
    secondaryHeader: [
      {
        label: '5月总数量 ',
        prop: 'mayNum',
        itemType: 'input',
        input: false,
        sortable: true
      },
      {
        label: '5月总金额 ',
        prop: 'mayPrice',
        itemType: 'input',
        input: false,
        sortable: true
      }
    ]
  },
  {
    label: '6月',
    prop: '6月',
    secondaryHeader: [
      {
        label: '6月总数量 ',
        prop: 'juneNum',
        itemType: 'input',
        input: false,
        sortable: true
      },
      {
        label: '6月总金额 ',
        prop: 'junePrice',
        itemType: 'input',
        input: false,
        sortable: true
      }
    ]
  },
  {
    label: '7月',
    prop: '7月',
    secondaryHeader: [
      {
        label: '7月总数量 ',
        prop: 'julyNum',
        itemType: 'input',
        input: false,
        sortable: true
      },
      {
        label: '7月总金额 ',
        prop: 'julyPrice',
        itemType: 'input',
        input: false,
        sortable: true
      }
    ]
  },
  {
    label: '8月',
    prop: '8月',
    secondaryHeader: [
      {
        label: '8月总数量 ',
        prop: 'augNum',
        itemType: 'input',
        input: false,
        sortable: true
      },
      {
        label: '8月总金额 ',
        prop: 'augPrice',
        itemType: 'input',
        input: false,
        sortable: true
      }
    ]
  },
  {
    label: '9月',
    prop: '9月',
    secondaryHeader: [
      {
        label: '9月总数量 ',
        prop: 'septNum',
        itemType: 'input',
        input: false,
        sortable: true
      },
      {
        label: '9月总金额 ',
        prop: 'septPrice',
        itemType: 'input',
        input: false,
        sortable: true
      }
    ]
  },
  {
    label: '10月',
    prop: '10月',
    secondaryHeader: [
      {
        label: '10月总数量 ',
        prop: 'octNum',
        itemType: 'input',
        input: false,
        sortable: true
      },
      {
        label: '10月总金额 ',
        prop: 'octPrice',
        itemType: 'input',
        input: false,
        sortable: true
      }
    ]
  },
  {
    label: '11月',
    prop: '11月',
    secondaryHeader: [
      {
        label: '11月总数量 ',
        prop: 'novNum',
        itemType: 'input',
        input: false,
        sortable: true
      },
      {
        label: '11月总金额 ',
        prop: 'novPrice',
        itemType: 'input',
        input: false,
        sortable: true
      }
    ]
  },
  {
    label: '12月',
    prop: '12月',
    secondaryHeader: [
      {
        label: '12月总数量 ',
        prop: 'deceNum',
        itemType: 'input',
        input: false,
        sortable: true
      },
      {
        label: '12月总金额 ',
        prop: 'decePrice',
        itemType: 'input',
        input: false,
        sortable: true
      }
    ]
  }
];
